export const APIConstants = {
  // baseUrl: "http://192.168.100.224:8000/api", //Optome Siliconplex
  // baseUrl: "http://192.168.18.64:8001/api", //StormFiber Siliconplex
  // baseUrl: "http://192.168.100.141:8000/api", // Optome Saqib
  // baseUrl: "http://192.168.1.64:8000/api", // lan qadri
  // baseUrl: "https://api.speedyhr.co.uk/api", //production
  // baseUrl: "https://api-dev.speedyhr.co.uk/api", // dev
  baseUrl: "https://api-uat.speedyhr.co.uk/api", // uat
  // baseUrl: "https://api-uae.speedyhr.co.uk/api", //uae
  // baseUrl:"http://192.168.0.113:8000/api",//SILICONPLEX TPLINK ABDULLAH
  // baseUrl: "https://f583-2400-adc1-475-5600-f0e-64b4-b0e9-ead7.ngrok-free.app/api",
  // baseUrl: "http://192.168.100.8:8000/api", ///abdullah
  auth: "/auth/",
  resentInvite: "/send-invitation/",
  deleteComLogo: "/company_logo/delete/",
  forgotPassword: "/forgot-password/",
  resetPassword: "/reset-password/",
  Dashboard: "/dashboard",
  publicHolidays: "/public-holidays/user",
  userWorkTimePattern: "working-hours/role/",
  DashboardAbsence: "/absencereport/dashboard",
  postConsultation: "consultation/create/",
  Staffing: "/rotas",
  ChangeStaffingStatus: "/rotas/publish",
  createStaffing: "/rotas/create/",
  getEmployees: "/users",
  createEmployee: "/users/create/",
  createBulkEmployee: "/users/bulk-create/",
  employeeWorkingStatus: "/profile/status/",
  getCountries: "/countries",
  locationsTypes: "location-types",
  absenceReport: "/absencereport/report/",
  latenessReport: "/lateness/report/",
  userProfile: "/profile/user/",
  updateUserPassword: "/change-password/",
  editLog: "update/log/overtime/",
  getUserAbsence: "profile/absence/",
  ChangeProfileStatus: "/profile/status/",
  userProfileOverTime: "/log/overtime/index/",
  logTImeOFF: "/log/timeoff/",
  getUserProfileContactInfo: "/profile/contact-info/",
  updateUserProfileContactInfo: "/profile/contact-info/",
  getUserProfilePersonalInfo: "/profile/personal-info/",
  updateUserProfilePersonalInfo: "/profile/personal-info/",
  saveUserProfileEmergencyContact: "/profile/emergency/",
  updateUserProfileEmergencyContact: "/profile/emergency/update/",
  getUserEmergencyContacts: "/profile/emergency/",
  deleteUserEmergencyContact: "/profile/emergency/delete/",
  cancelUserOverTime: "/cancel/log/overtime/",
  getUserEmploymentContractInfo: "/user/contract-info/",
  // getUserEmployementRoleInfo: "/user/contract-info/",
  updateUserEmployemenetRoleInfo: "/profile/update/contract-info/",
  getUserSalaryInfo: "/salary-info/",
  updateUserSalaryInfo: "/update/salary-info/",
  getUserSensitiveInformation: "/sensitive-info/",
  updateUserSensitiveInformation: "/update/sensitive-info/",
  updateUerEmployementAnnualLeave: "/profile/update/annual-leave/",
  updateUserContractInfo: "/profile/update/contract-info/",
  createUserOverTime: "/log/overtime/",
  getShifts: "/shifts",
  createShifts: "/shifts/create/",
  getAllFolders: "/folders/",
  createFolder: "/folders/create/",
  uploadFile: "/files/create/",
  downlodFile: "/file/download/",
  getAbsenceCalender: "/annual-leaves",
  getPendingLeave: "/annual-leaves/pending",
  getDeclinedLeave: "/annual-leaves/declined",
  updateShift: "/shifts/edit/",
  getAbsenceType: "/absence-types",
  addTimeOff: "/annual-leaves",
  pendingRequestAction: "/annual-leaves/pending/status/",
  deleteAbsence: "/annual-leaves/delete/",
  RequestAbsenceData: "/annual-leaves/edit/",
  updateAbsence: "/annual-leaves/update/",
  updateOverTime: "/edit/log/overtime/",
  annualLeaveSummaryReport: "/annual-leave/report/",
  employeeDetailsReport: "/employee/report",
  timesheetReport: "/timesheet/report",
  timesheet: "/timesheet/",
  timesheetHistory: "/timesheet/history/",
  getUserTimeSheetData: "/breaks/",
  toggleBreak: "/breaks/create/",
  placesToWork: "/workplaces/",
  // workingTimePattern: "/workingtimepattern/",
  files: "/files/",
  folders: "/folders/",
  checkEmailStatus: "/users/emailcheck/",
  editRota: "rotas/rename/",
  editStaffing: "rotas/edit/",
  updateUserEmpInfo: "users/settings/",
  deleteRota: "rotas/delete/",
  updateUserImage: "profile-image/",
  getStaffing: "rotas/view/",
  getStaffing2: "rotas/view2/",
  getUserMedicalInfo: "profile/medical-info/",
  updateUserMedicalInfo: "profile/medical-info/",
  assignStaffEmployees: "rotas/assignemployee/",
  unAssignStaffEmployee: "rotas/unassignemployee/",
  deleteShifts: "shifts/delete/",
  downloadBulkCSV: "/employeebulkcsvsample/",
  // uploadBulkCSV: "/users/createBulk/",
  mandatoryLeaves: "/mandatory-leaves",
  deleteMandatoryLeaves: "/mandatory-leaves/delete/",
  createMandtoryLeaves: "/mandatory-leaves",
  deletePlacesOfwork: "/workplaces/delete/",
  deleteProfilePicture: "profile-image/delete/",
  getRolesSetting: "/roles-settings",
  deleteRolesSetting: "/roles-settings/delete/",
  updateRolesSetting: "roles-settings/update/",
  getRoles: "/roles",
  deleteRole: "/roles/delete/",
  updateRole: "/roles/update/",
  modules: "/modules",
  workingHoursPattern: "/working-hours/",
  roles: "roles",
  userPrivileges: "/roles/privileges/",
  allPrevilages: "/privileges",
  assignPrevilages: "/roles/privileges/assign/",
  companySettingsOB: "/company",
  dowloadBulkEmp: "/users/bulk-upload/sample",
  uploadBulkUsersCSV: "/users/bulk-upload",
  companyEmployees: "/company/users/unassigned/",
  saveCompanyUser: "/users/assign/",
  generateDocument: "/onboarding/verify/",
  sharedDocument: "shared-documents",
  regenerateDocuments: "/regenerate-documents",
};
