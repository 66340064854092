import React from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { formattedDateForAPI } from "../../../../Utilities/index";
import EmpDetailsField from "../Components/EmpDetailsField";
import useLocalisedConstants from "../../../../customHooks/useLocalisedConstants";
import EmplDropDown from "../Components/EmplDropDown";

export default function EmployeeDetails({
  employeeForm,
  setEmployeeForm,
  isValidatingEmail,
  currentType,
}) {
  const { t } = useTranslation();

  const appConstants = useLocalisedConstants();
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-between gap-2 col-md-12 col-sm-12">
        <div className="d-flex gap-2 col-lg-12 col-md-12 col-sm-12 flex-wrap justify-between">
          <EmplDropDown
            title={t("title")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                title: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            value={employeeForm.title}
            dataArray={appConstants.titleArray}
            displayKey="title"
            valueKey="value"
          // isRequired={true}
          />
          <EmpDetailsField
            title={t("initials")}
            placeholder={t("initials")}
            type={"text"}
            value={employeeForm.initials}
            isRequired={false}
            maxLength={5}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                initials: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("firstName")}
            placeholder={t("firstName")}
            type={"text"}
            value={employeeForm.first_name}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                first_name: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("lastName")}
            placeholder={t("lastName")}
            type={"text"}
            value={employeeForm.last_name}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                last_name: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("email")}
            placeholder={t("email")}
            type={"email"}
            value={employeeForm.email}
            isRequired={true}
            validate={isValidatingEmail}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                email: e.target.value,
              })
            }
          />
          <EmpDetailsField
            title={t("phoneNumber")}
            placeholder={t("phoneNumber")}
            type={"tel"}
            value={employeeForm.mobile_phone}
            // isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                mobile_phone: e.target.value,
              })
            }
          />
          <EmplDropDown
            title={t("gender")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                gender: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            value={employeeForm.gender}
            dataArray={appConstants.genderArray}
            displayKey="title"
            valueKey="value"
          // isRequired={true}
          />
          <EmplDropDown
            title={t("maritalStatus")}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                marital_status: get(JSON.parse(e.target.value), "value", ""),
              })
            }
            value={employeeForm.marital_status}
            dataArray={appConstants.maritalStatuses}
            displayKey="title"
            valueKey="value"
          // isRequired={true}
          />
          <EmpDetailsField
            title={t("dateOfBirth")}
            placeholder={t("dateOfBirth")}
            type={"date"}
            // isRequired={true}
            value={employeeForm.date_of_birth}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                date_of_birth: formattedDateForAPI(e.target.value),
              })
            }
          />
          <EmpDetailsField
            title={t("startDate")}
            placeholder={t("startDate")}
            type={"date"}
            value={employeeForm.start_date}
            validate={false}
            isRequired={true}
            onChange={(e) =>
              setEmployeeForm({
                ...employeeForm,
                start_date: formattedDateForAPI(e.target.value),
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
