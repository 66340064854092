import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  differenceInHours,
  getTimeFromDateTimestamp,
  isUserLineManager,
} from "../../Utilities/index";
import { Applogger } from "../../Helpers/Logger";
import { getAlphaNumericString } from "../../Helpers/AppValidators";
import {
  addTimeOff,
  RequestCalenderData,
  RequestAbsenceData,
  updateTimeOff,
} from "../../Redux/reducers/CalenderReducer";
import {
  showFaliureToast,
  showSuccessToast,
  unixToDateFormater,
} from "../../Utilities";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import UseUsers from "../../Helpers/useUsers";
import moment from "moment";
import HoursMinutesField from "./Components/HoursMinutesField";
import ShiftDropDown from "./Components/ShiftDropDown";
import AbsenceDropDown from "./Components/AbsenceDropDown";
import NotesView from "./Components/NotesView";
import Spinner from "../Spinner";
import Header from "../Header/Header";
import AppConstants from "../../Helpers/AppConstants";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";

export default function AddTimeOff() {
  // i18n
  const { t } = useTranslation();

  // Hooks
  const { } = UseUsers(false);
  useTitleHandler(t("absence"));

  // Dispatcher
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Params
  const [params] = useSearchParams();
  const absenceType = params.get("type");
  const currentUser = params.get("user");
  const absenceId = params.get("absence_id");
  const redirectPage = params.get("page");

  // Reducer States
  const { allEmployees, loading: empLoading } = useSelector(
    (state) => state.employees
  );
  const {
    absenceTypes,
    loading: calenderLoading,
    absenceLoading,
  } = useSelector((state) => state.calender);
  const { token, role, user } = useSelector(
    (state) => state.AuthenticationReducer
  );

  const { lng } = useSelector((state) => state.languageReducer);

  // Privlidges

  const { exist: createOthersAnnualLeave } = usePrevilagesExist(
    AppConstants.previlages.createOthersAnnualLeave
  );
  const { exist: createAnnualLeave } = usePrevilagesExist(
    AppConstants.previlages.createAnnualLeave
  );
  // Constants
  const shiftTypes = {
    fullDay: "Full Day",
    halfDay: "Half Day",
  };

  const shiftTimings = {
    morning: "Morning",
    evening: "Evening",
  };
  const userId = get(user, "user_id", "");

  // States
  const [sendingAbsenceData, setSendingAbsenceData] = useState({
    employee: "",
    absenceType: "",
    note: "",
    hoursDeducted: 0,
    minsDeducted: 0,
    durationHours: 0,
    durationMins: 0,
    start_date: "",
    start_time: "",
    start_type: shiftTypes.fullDay,
    start_half_day_type: shiftTimings.morning,
    end_date: "",
    end_time: "",
    end_type: shiftTypes.fullDay,
    end_half_day_type: shiftTimings.morning,
  });
  // const [currentEmployee, setCurrentEmployee] = useState(null);

  // const currentWorkingHours = get(
  //   currentEmployee,
  //   "average_working_day_hours",
  //   0
  // );

  useEffect(() => {
    if (!token) {
      navigate(AppConstants.routes.login);
    }
  }, []);

  useEffect(() => {
    setSendingAbsenceData({
      ...sendingAbsenceData,
      minsDeducted: calculateDeductedMins(),
      hoursDeducted: calculateDeductedHours(),
    });
  }, [
    sendingAbsenceData.start_date,
    sendingAbsenceData.start_time,
    sendingAbsenceData.end_date,
    sendingAbsenceData.end_time,
  ]);

  useEffect(() => {
    if (token) {
      let employeeIndex = -1;
      let currentAbsenceType = null;

      if (currentUser) {
        employeeIndex = allEmployees.findIndex(
          (val) => val.user_id == currentUser
        );
      }

      if (absenceType && absenceTypes.length > 0) {
        var currentAbsenceIndex = null;
        currentAbsenceIndex = absenceTypes.findIndex((val) => {
          return (
            getAlphaNumericString(val.slug) ==
            getAlphaNumericString(absenceType)
          );
        });

        if (currentAbsenceIndex != null && currentAbsenceIndex !== -1) {
          currentAbsenceType =
            absenceTypes[currentAbsenceIndex].absence_type_id;
        }
      }

      if (absenceId) {
        getAbsence(absenceId);
      }

      setSendingAbsenceData({
        ...sendingAbsenceData,
        absenceType: currentAbsenceType,
        employee:
          employeeIndex != -1
            ? Number(currentUser)
            : role != "Owner"
              ? get(user, "user_id", "")
              : "",
      });
      // getCurrentDate();
    }
  }, [params]);

  useEffect(() => {
    currentEmployeeHandler();
  }, [sendingAbsenceData.employee, allEmployees]);

  const getAbsence = (id) => {
    dispatch(RequestAbsenceData({ token, id, lng }))
      .then(unwrapResult)
      .then((res) => {
        settingAbsenceData(res.data);
        Applogger("Response at RequestAbsenceData", res);
      })
      .catch((err) => {
        Applogger("Error at RequestAbsenceData", err.message);
      });
  };

  const convertTimestampToDate = (timestamp) => {
    return unixToDateFormater(timestamp, AppConstants.defaultDateFormat);
  };

  const adminAbsenceTypes = () => {
    return absenceTypes.filter((val) => {
      return (val.name !== "Mandatory Leave"
        //Hide Public holiday when workin on web on mobile already hide
        // && val.name !== "Public Holidays"
      )
    });
  };

  const usersAbsenceTypes = () => {
    return absenceTypes.filter((val) => {
      return (
        // val.name !== AppConstants.absenceTypesNames.lateness &&
        val.name !== AppConstants.absenceTypesNames.sickness &&
        val.name !== AppConstants.absenceTypesNames.dependentLeave &&
        val.name !== AppConstants.absenceTypesNames.mandatoryLeave &&
        val.name !== "Public Holidays"
      );
    });
  };

  const onstartTimeChange = (time) => {
    setSendingAbsenceData({
      ...sendingAbsenceData,
      start_time: time.target.value,
      minsDeducted: calculateDeductedMins(),
      hoursDeducted: calculateDeductedHours(),
    });
  };

  const currentEmployeeHandler = () => {
    const allUsers = [...allEmployees];

    const currentEmployeeIndex = allUsers.findIndex(
      (employee) => employee.user_id == sendingAbsenceData.employee
    );
    if (currentEmployeeIndex !== -1) {
      const curr = allUsers[currentEmployeeIndex];

      // setCurrentEmployee(curr);
    }
  };

  const calculateDeductedMins = () => {
    const { end_date, end_time, start_date, start_time } = sendingAbsenceData;
    if (end_date && end_time && start_date && start_time) {
      var start = moment(new Date(start_date + " " + start_time)); // Start date
      var end = moment(end_date + " " + end_time); // End date
      var duration = moment.duration(end.diff(start));
      var hours = duration.hours();
      var minutes = duration.minutes();

      return minutes;
    } else {
      return 0;
    }
    // if (
    //   sendingAbsenceData.end_date &&
    //   sendingAbsenceData.end_time &&
    //   sendingAbsenceData.start_date &&
    //   sendingAbsenceData.start_time
    // ) {
    //   let startDate = new Date(
    //     `${sendingAbsenceData.start_date} ${sendingAbsenceData.start_time}`
    //   );
    //   let endDate = new Date(
    //     `${sendingAbsenceData.end_date} ${sendingAbsenceData.end_time}`
    //   );
    //   var difference = endDate.getTime() - startDate.getTime();
    //   var resultInMinutes = Math.round(difference / 60000);
    //   let finalHours = parseInt(resultInMinutes / 60);
    //   let finalMinutes = resultInMinutes % 60;
    //   return finalMinutes;
    // } else {
    //   return 0;
    // }
  };

  function calculateDeductedHours() {
    const { end_date, end_time, start_date, start_time } = sendingAbsenceData;
    if (end_date && end_time && start_date && start_time) {
      var start = moment(new Date(start_date + " " + start_time)); // Start date
      var end = moment(end_date + " " + end_time); // End date
      var duration = moment.duration(end.diff(start));
      var days = duration.days();
      var hours = duration.hours();
      var minutes = duration.minutes();
      if (days > 0) {
        hours = hours + days * 24;
      }
      return hours;
    } else {
      return 0;
    }
    // if (getCurrentUserType() == AppConstants.userRoleTypes.hours) {
    //   if (sendingAbsenceData.end_date && sendingAbsenceData.start_date) {
    //     const startDateObj = new Date(
    //       sendingAbsenceData.start_date + " " + sendingAbsenceData.start_time
    //     );
    //     const endDateObj = new Date(
    //       sendingAbsenceData.end_date + " " + sendingAbsenceData.end_time
    //     );

    //     const differenceInMilliseconds = endDateObj - startDateObj;
    //     var hourdifference = Math.abs(startDateObj - endDateObj) / 36e5;
    //     let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    //     let hours =
    //       Math.floor(
    //         (differenceInMilliseconds % (1000 * 60 * 60 * 24)) /
    //           (1000 * 60 * 60)
    //       ) +
    //       days * currentWorkingHours;
    //     if (differenceInMilliseconds === 0) {
    //       if (hourdifference >= 0 && isHourlyLeave()) {
    //         sendingAbsenceData.hoursDeducted =
    //           hours + Math.floor(hourdifference / currentWorkingHours);
    //         return (hours += Math.floor(hourdifference / currentWorkingHours));
    //       } else {
    //         sendingAbsenceData.hoursDeducted = currentWorkingHours;
    //         return currentWorkingHours;
    //       }
    //     }

    //     if (days === 0) {
    //       if (hourdifference >= 0 && isHourlyLeave()) {
    //         sendingAbsenceData.hoursDeducted = hours += Math.floor(
    //           hourdifference / currentWorkingHours
    //         );
    //         return Math.floor(hourdifference);
    //       } else {
    //         sendingAbsenceData.hoursDeducted = currentWorkingHours;
    //         return (hours = currentWorkingHours);
    //       }
    //     } else {
    //       hours += days * currentWorkingHours;
    //     }
    //     sendingAbsenceData.hoursDeducted = hours;
    //     if (hours) {
    //       return hours;
    //     } else {
    //       return 0;
    //     }
    //   } else {
    //     return 0;
    //   }
    // } else {
    //   if (
    //     sendingAbsenceData.end_date &&
    //     sendingAbsenceData.end_time &&
    //     sendingAbsenceData.start_date &&
    //     sendingAbsenceData.start_time
    //   ) {
    //     const startDateObj = new Date(
    //       sendingAbsenceData.start_date + " " + sendingAbsenceData.start_time
    //     );
    //     const endDateObj = new Date(
    //       sendingAbsenceData.end_date + " " + sendingAbsenceData.end_time
    //     );

    //     const differenceInMilliseconds = endDateObj - startDateObj;
    //     var hourdifference = Math.abs(startDateObj - endDateObj) / 36e5;
    //     let days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    //     let hours =
    //       Math.floor(
    //         (differenceInMilliseconds % (1000 * 60 * 60 * 24)) /
    //           (1000 * 60 * 60)
    //       ) +
    //       days * currentWorkingHours;
    //     if (differenceInMilliseconds === 0) {
    //       if (hourdifference >= 0) {
    //         return (hours +=
    //           Math.floor(hourdifference / currentWorkingHours) - 1);
    //       } else {
    //         return currentWorkingHours;
    //       }
    //     }
    //     if (days === 0) {
    //       if (hourdifference >= 0) {
    //         hours += Math.floor(hourdifference / currentWorkingHours);
    //       } else {
    //         hours = currentWorkingHours;
    //       }
    //     } else {
    //       hours = days * currentWorkingHours;
    //       return hourdifference;
    //     }
    //     return hourdifference;
    //   } else {
    //     return 0;
    //   }
    // }
  }

  const onEndTimeChange = (time) => {
    setSendingAbsenceData({
      ...sendingAbsenceData,
      end_time: time.target.value,
      minsDeducted: calculateDeductedMins(),
      hoursDeducted: calculateDeductedHours(),
    });
  };

  // const converTimeStampToTime = (timeStamp) => {
  //   return unixToDateFormater(timeStamp, "HH:mm");
  // };

  const settingAbsenceData = (data) => {
    setSendingAbsenceData({
      ...sendingAbsenceData,
      employee: data.user_id,
      absenceType: data.absence_type_id,
      start_type: data.start_type,
      start_time: getTimeFromDateTimestamp(
        data.start_time,
        AppConstants.dateFormatWithTimeAM
      ),
      start_date: convertTimestampToDate(data.start_date),
      start_half_day_type: data.start_half_day_type ?? shiftTimings.morning,
      end_type: data.end_type,
      end_time: getTimeFromDateTimestamp(
        data.end_time,
        AppConstants.dateFormatWithTimeAM
      ),
      end_date: convertTimestampToDate(data.end_date),
      end_half_day_type: data.end_half_day_type ?? shiftTimings.morning,
      hoursDeducted: calculateDeductedHours(),
      minsDeducted: calculateDeductedMins(),
      note: data.notes ?? "",
    });
  };

  const getCurrentDate = () => {
    let today = new Date();
    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let hour = differenceInHours(date, date);
    const separator = "-";
    today = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;

    if (role !== AppConstants.roleTypes.Admin && userId) {
      setSendingAbsenceData({
        ...sendingAbsenceData,
        employee: userId,
        start_date: today,
        end_date: today,
      });
    } else {
      setSendingAbsenceData({
        ...sendingAbsenceData,
        start_date: today,
        end_date: today,
      });
    }
  };

  const updateCalenderData = () => {
    dispatch(RequestCalenderData({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at RequestCalenderData", res);
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 401") {
          showFaliureToast("Please Login To Continue");
        }
        Applogger("Error at RequestCalenderData", err.message);
      });
  };

  const getAbsenceName = (id) => {
    let finalName = "";
    absenceTypes.forEach((val) => {
      if (val.absence_type_id == id) {
        finalName = get(val, "name", "");
      }
    });

    return finalName;
  };

  const addTimeOffHandler = () => {
    if (
      isHourlyLeave() ||
      getCurrentUserType() == AppConstants.userRoleTypes.hours
    ) {
      if (
        sendingAbsenceData.hoursDeducted < 0 ||
        sendingAbsenceData.minsDeducted < 0
      ) {
        showFaliureToast(
          "End date & time must be greater than start date & time"
        );
        return;
      }
    }

    if (absenceId) {
      handleUpdateTimeOff();
    } else {
      handleAddTimeOff();
    }
  };

  const handleUpdateTimeOff = () => {
    dispatch(
      updateTimeOff({
        token: token,
        data: sendingAbsenceData,
        id: absenceId,
        lng,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        updateCalenderData();
        showSuccessToast("Absence Updated Successfully");
        navigate(AppConstants.routes.calendar);
      })
      .catch((err) => {
        showFaliureToast(err ?? "Unable to update absence");
        Applogger("Error at updateTimeOff", err);
      });
  };

  const handleAddTimeOff = () => {
    dispatch(addTimeOff({ token, sendingAbsenceData: sendingAbsenceData, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at addTimeOff", res);
        updateCalenderData();
        if (role == AppConstants.roleTypes.Admin) {
          showSuccessToast("Absence Added Successfully");
        } else {
          showSuccessToast("Absence Pending For Approval");
        }
        setSendingAbsenceData({ ...sendingAbsenceData, note: "" });
        navigate(AppConstants.routes.calendar);
      })
      .catch((err) => {
        showFaliureToast(err ?? "Unable to add absence");
        Applogger("Error at addTimeOff", err);
      });
  };

  const getEndDate = (startDate) => {
    let finalDate = sendingAbsenceData.end_date;

    if (sendingAbsenceData.end_date) {
      const dateToMatch = moment(sendingAbsenceData.end_date).format(
        AppConstants.defaultDateFormat
      );
      const isAvailed = moment(startDate).isAfter(dateToMatch);

      if (isAvailed) {
        finalDate = "";
      }
    }

    return finalDate;
  };

  const onStartTimeChangeHandler = (e) => {
    setSendingAbsenceData({
      ...sendingAbsenceData,
      start_date: e.target.value,
      end_date: getEndDate(e.target.value),
      minsDeducted: calculateDeductedMins(),
      hoursDeducted: calculateDeductedHours(),
    });
  };

  const onStartTypeChange = (e) => {
    if (sendingAbsenceData.start_date === sendingAbsenceData.end_date) {
      // Need to check
      // if (sendingAbsenceData.end_time !== e.target.value) {
      //   sendingAbsenceData.end_time = e.target.value;
      // }
    }
    if (e.target.value == shiftTypes.halfDay) {
      sendingAbsenceData.start_half_day_type = shiftTimings.morning;
    } else {
      sendingAbsenceData.start_half_day_type = "";
    }
    sendingAbsenceData.start_type = e.target.value;

    setSendingAbsenceData({ ...sendingAbsenceData });
  };

  const endTypeChangeHandler = (e) => {
    if (sendingAbsenceData.start_date === sendingAbsenceData.end_date) {
      sendingAbsenceData.durationHours = 24;
      // Need to check
      // if (sendingAbsenceData.start_type !== e.target.value) {
      //   sendingAbsenceData.start_type = e.target.value;
      // }
    }
    if (e.target.value == shiftTypes.halfDay) {
      sendingAbsenceData.end_half_day_type = shiftTimings.morning;
    } else {
      sendingAbsenceData.end_half_day_type = "";
    }
    sendingAbsenceData.end_type = e.target.value;

    setSendingAbsenceData({ ...sendingAbsenceData });
  };

  const endDateHandler = (e) => {
    setSendingAbsenceData({
      ...sendingAbsenceData,
      end_date: e.target.value,
      minsDeducted: calculateDeductedMins(),
      hoursDeducted: calculateDeductedHours(),
    });
  };

  const handleProfileNavigation = () => {
    let user_id = 0;
    if (sendingAbsenceData.employee == 0) {
      user_id = userId;
    } else {
      user_id = sendingAbsenceData.employee;
    }
    if (redirectPage == "profile") {
      navigate(AppConstants.routes.profile + `?id=${user_id}`);
    } else if (redirectPage == "dashboard") {
      navigate(AppConstants.routes.dashboard);
    } else {
      navigate(AppConstants.routes.calendar, { state: { data: userId } });
    }
  };

  const getCurrentUserType = () => {
    let userType = "";
    allEmployees.forEach((employee) => {
      if (employee.user_id == sendingAbsenceData.employee) {
        userType = get(employee, "user_type", "");
      }
    });
    return userType;
  };

  const onStartHalfDayTypeChange = (e) => {
    sendingAbsenceData.start_half_day_type = e.target.value;
    setSendingAbsenceData({ ...sendingAbsenceData });
  };

  const onEndHalfDayChange = (e) => {
    sendingAbsenceData.end_half_day_type = e.target.value;
    setSendingAbsenceData({ ...sendingAbsenceData });
  };

  const isHourlyLeave = () => {
    return (
      getAbsenceName(sendingAbsenceData.absenceType) ==
      AppConstants.absenceTypesNames.toil ||
      getAbsenceName(sendingAbsenceData.absenceType) ==
      AppConstants.absenceTypesNames.lateness
    );
  };

  const StartDateHandler = () => {
    return (
      <div className="row align-items-center">
        <div className="col-12 col-md-4 col-sm-12 px-0">
          <h6>{t("start")}</h6>
        </div>
        <div className="col-12 col-md-6 col-sm-12 px-0">
          <div className="d-flex flex-wrap-sm gap-2">
            <div className="col-12 col-md">
              <input
                value={sendingAbsenceData.start_date}
                onChange={(e) => onStartTimeChangeHandler(e)}
                className="filter-form"
                type={"date"}
              />
            </div>
            {getCurrentUserType() == AppConstants.userRoleTypes.hours ||
              isHourlyLeave() ? (
              <div className="col-12 col-md">
                <input
                  type={"time"}
                  value={sendingAbsenceData.start_time}
                  format={"HH:mm A"}
                  className="filter-form "
                  onChange={(e) => onstartTimeChange(e)}
                />
              </div>
            ) : (
              <div className="row col-12 col-md gap-2 justify-content-between">
                <ShiftDropDown
                  dataArray={Object.values(shiftTypes)}
                  defaultValue={sendingAbsenceData.start_type}
                  value={sendingAbsenceData.start_type}
                  onChange={onStartTypeChange}
                />
                {sendingAbsenceData.start_type == shiftTypes.halfDay && (
                  <ShiftDropDown
                    dataArray={Object.values(shiftTimings)}
                    defaultValue={sendingAbsenceData.start_half_day_type}
                    value={sendingAbsenceData.start_half_day_type}
                    onChange={onStartHalfDayTypeChange}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const EndDateHandler = () => {
    return (
      <div className="row align-items-center">
        <div className="col-12 col-md-4 col-sm-12 px-0">
          <h6>{t("end")}</h6>
        </div>
        <div className="col-12 col-md-6 col-sm-12 px-0">
          <div className="d-flex flex-wrap-sm gap-2">
            <div className="col-12 col-md">
              <input
                value={sendingAbsenceData.end_date}
                min={
                  sendingAbsenceData.start_date
                    ? sendingAbsenceData.start_date
                    : ""
                }
                onChange={endDateHandler}
                className="filter-form"
                type={"date"}
              />
            </div>
            {getCurrentUserType() == AppConstants.userRoleTypes.hours ||
              isHourlyLeave() ? (
              <div className="col-12 col-md">
                <input
                  type={"time"}
                  value={sendingAbsenceData.end_time}
                  className="filter-form "
                  onChange={onEndTimeChange}
                  format={"HH:mm A"}
                />
              </div>
            ) : (
              <div className="row col-12 col-md gap-2 justify-content-between">
                <ShiftDropDown
                  dataArray={Object.values(shiftTypes)}
                  defaultValue={sendingAbsenceData.end_type}
                  value={sendingAbsenceData.end_type}
                  onChange={endTypeChangeHandler}
                />
                {sendingAbsenceData.end_type == shiftTypes.halfDay && (
                  <ShiftDropDown
                    dataArray={Object.values(shiftTimings)}
                    defaultValue={sendingAbsenceData.end_half_day_type}
                    value={sendingAbsenceData.end_half_day_type}
                    onChange={onEndHalfDayChange}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const currentAbsenceTypeFinder = (id) => {
    const currentIndex = absenceTypes.findIndex(
      (val) => val.absence_type_id == id
    );
    if (currentIndex !== -1) {
      const currentAbsence = absenceTypes[currentIndex];

      const currName = get(currentAbsence, "name", "");

      if (
        currName == AppConstants.absenceTypesNames.lateness ||
        currName == AppConstants.absenceTypesNames.toil
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isValidDataAdded = () => {
    const {
      employee,
      absenceType,
      hoursDeducted,
      minsDeducted,
      start_date,
      start_time,
      start_type,
      end_date,
      end_time,
      end_type,
    } = sendingAbsenceData;

    if (employee || absenceType) {
      if (getCurrentUserType() == AppConstants.userRoleTypes.daily) {
        if (isHourlyLeave()) {
          return (
            !start_date ||
            !start_time ||
            !end_date ||
            !end_time ||
            !hoursDeducted < 0 ||
            !minsDeducted < 0 ||
            !employee ||
            !absenceType
          );
        }
        return (
          !start_date ||
          !start_type ||
          !end_date ||
          !end_type ||
          !employee ||
          !absenceType
        );
      }
      if (getCurrentUserType() == AppConstants.userRoleTypes.hours) {
        return (
          !start_date ||
          !start_time ||
          !end_date ||
          !end_time ||
          !hoursDeducted < 0 ||
          !minsDeducted < 0 ||
          !employee ||
          !absenceType
        );
      }
      if (isHourlyLeave()) {
        return (
          !start_date ||
          !start_time ||
          !end_date ||
          !end_time ||
          !hoursDeducted < 0 ||
          !minsDeducted < 0 ||
          !employee ||
          !absenceType
        );
      }
      return (
        !employee ||
        !absenceType ||
        !start_date ||
        !start_time ||
        !end_date ||
        !end_time
      );
    } else {
      return !employee || !absenceType;
    }
  };

  return (
    <div>
      {(empLoading == "pending" ||
        calenderLoading == "pending" ||
        absenceLoading == "pending") && <Spinner />}
      <Header />
      <br />
      <div className="container_custom">
        <h1 className="top-heading">
          {absenceId ? t("updateAbsence") : t("addAbsence")}
        </h1>
        <br />
        <hr />
        <br />
        {createOthersAnnualLeave && (
          <AbsenceDropDown
            title={t("employee")}
            isDisabled={absenceId}
            value={sendingAbsenceData.employee}
            showSearch={true}
            dataArray={allEmployees}
            valueKey={"user_id"}
            displayKey={"first_name"}
            displayKey2={"last_name"}
            onChange={(e) => {
              setSendingAbsenceData({
                ...sendingAbsenceData,
                employee: e,
                note: "",
                hoursDeducted: calculateDeductedHours(),
                minsDeducted: calculateDeductedMins(),
                durationHours: 0,
                durationMins: 0,
                start_date: "",
                start_time: "",
                start_type: shiftTypes.fullDay,
                start_half_day_type: "",
                end_date: "",
                end_time: "",
                end_type: shiftTypes.fullDay,
                end_half_day_type: "",
              });
            }}
          />
        )}
        <AbsenceDropDown
          title={t("absenceType")}
          isDisabled={absenceId}
          value={sendingAbsenceData.absenceType}
          showSearch={true}
          displayKey="name"
          valueKey="absence_type_id"
          colorKey={"colour"}
          dataArray={
            isUserLineManager(user) || createOthersAnnualLeave
              ? adminAbsenceTypes()
              : usersAbsenceTypes()
          }
          onChange={(e) => {
            setSendingAbsenceData({
              ...sendingAbsenceData,
              absenceType: e,
              note: "",
              hoursDeducted: calculateDeductedHours(),
              minsDeducted: calculateDeductedMins(),
              durationHours: 0,
              durationMins: 0,
              start_time: "",
              start_type: shiftTypes.fullDay,
              start_half_day_type: "",
              end_time: "",
              end_type: shiftTypes.fullDay,
              end_half_day_type: "",
            });
          }}
        />
        {StartDateHandler()}
        {EndDateHandler()}
        {(getCurrentUserType() == AppConstants.userRoleTypes.hours ||
          currentAbsenceTypeFinder(sendingAbsenceData.absenceType)) && (
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-sm-12 px-0">
                <h6>{t("hoursDeducted")}</h6>
              </div>
              <div className="col-12 col-md-6 col-sm-12 px-0">
                <div className="d-flex flex-wrap-sm gap-2">
                  <HoursMinutesField
                    value={parseInt(sendingAbsenceData.hoursDeducted)}
                    placeholder={"Hours"}
                    title={"Hrs"}
                    addClass={"pr-0"}
                    onChange={(e) =>
                      setSendingAbsenceData({
                        ...sendingAbsenceData,
                        hoursDeducted: e.target.value,
                      })
                    }
                  />
                  <HoursMinutesField
                    value={parseInt(sendingAbsenceData.minsDeducted)}
                    placeholder={"Mins"}
                    title={"Mins"}
                    onChange={(e) =>
                      setSendingAbsenceData({
                        ...sendingAbsenceData,
                        minsDeducted: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        <NotesView
          title={t("notes")}
          value={sendingAbsenceData.note}
          onChange={(e) => {
            setSendingAbsenceData({
              ...sendingAbsenceData,
              note: e.target.value,
            });
          }}
        />
        <div className="d-flex mt-4">
          <button
            onClick={() => addTimeOffHandler()}
            className="btn btn-primary-cs"
            disabled={isValidDataAdded()}
          >
            {absenceId ? t("updateAbsence") : t("addAbsence")}
          </button>
          <button
            onClick={() => handleProfileNavigation()}
            className="btn btn-primary-outline-cs mx-3"
          >
            {redirectPage
              ? `${t("backto")} ${redirectPage ?? ""}`
              : t("goToCalendar")}
          </button>
        </div>
      </div>
    </div>
  );
}
