import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { Applogger } from "../../../Helpers/Logger";
import { useTranslation } from "react-i18next";
import {
  getUserProfileOverTime,
  cancelUserProfileOverTime,
  getUserProfileOverTimeOff,
} from "../../../Redux/reducers/ProfileReducers";
import PayableCard from "./payableCard";
import OverTimeCancelModal from "./OverTimeCancelModal";
import OverTimeCard from "./overTimeCard";
import AppConstants from "../../../Helpers/AppConstants";
import usePrevilagesExist from "../../../Helpers/PrivilegesFuncs";
import LeaveYearDropDownOpts from "../../LeaveYear/LeaveYearDropDownOpts";
import moment from "moment";

export default function UserOvertime({ currentUserId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { overTimeDetails, user, overTimeOff } = useSelector(
    (state) => state.profile
  );

  const { exist: createOvertimeLog } = usePrevilagesExist(
    AppConstants.previlages.createOvertimeLog
  );

  const { t } = useTranslation();
  const [yearFilter, setYearFilter] = useState("");
  const currentYear = new Date().getFullYear();

  const currentYearEndDate = `31-12-${currentYear}`;
  const currentYearStartDate = `01-01-${currentYear}`;

  const previousYearStartDate = `01-01-${currentYear - 1}`;
  const previousYearEndDate = `31-12-${currentYear - 1}`;
  const futureYearStartDate = `01-01-${currentYear + 1}`;
  const futureYearEndDate = `31-12-${currentYear + 1}`;
  const [currentOverTime, setCurrent] = useState([]);
  const [currentOverId, setCurrentOverId] = useState("");
  const [overTimeHistory, setHistory] = useState([]);
  const [overTimeCancel, setCancel] = useState([]);
  const [cardData, setCardData] = useState({
    totalToilLoggedHour: 0,
    totalToilLoggedMins: 0,
    totalPayableLoggedHour: 0,
    totalPayableLoggedMins: 0,
    toilTakenHour: 0,
    toilTakenMin: 0,
  });

  useEffect(() => {
    if (yearFilter) {
      getUserProfileOverTimeRequest();
      getUserProfileOverTimeOffRequest();
    }
  }, [yearFilter, currentUserId]);

  useEffect(() => {
    setOverTimeData();
  }, [overTimeDetails, overTimeOff]);

  const handleMinutes = (hours, minutes) => {
    while (minutes >= 60) {
      hours += 1;
      minutes -= 60;
    }
    return { hours, minutes };
  };
  const setOverTimeData = () => {
    if (overTimeDetails.length > 0) {
      let currentData = [];
      let historyData = [];
      let cancelData = [];
      let currentTimeHours = 0;
      let historyTimeHours = 0;
      let currentTimeMins = 0;
      let historyTimeMins = 0;
      for (let i = 0; i < overTimeDetails.length; i++) {
        const element = overTimeDetails[i];
        if (element.time_history == "canceled") {
          cancelData.push(element);
        } else if (element.time_history == "history") {
          historyData.push(element);
        } else if (element.time_history == "current") {
          currentData.push(element);
        }
      }
      currentData.forEach((element) => {
        currentTimeHours += parseInt(element.overtime_length.split(":")[0]);
        currentTimeMins += parseInt(element.overtime_length.split(":")[1]);
      });

      historyData.forEach((element) => {
        historyTimeHours += parseInt(element.overtime_length.split(":")[0]);
        historyTimeMins += parseInt(element.overtime_length.split(":")[1]);
      });
      setCurrent(currentData);
      setHistory(historyData);
      setCancel(cancelData);

      const totalMins = currentTimeMins + historyTimeMins;
      const totalHours = currentTimeHours + historyTimeHours;
      const totalTime = handleMinutes(totalHours, totalMins);

      setCardData({
        ...cardData,
        totalToilLoggedHour: totalTime.hours,
        totalToilLoggedMins: totalTime.minutes,
      });
    } else {
      setCurrent([]);
      setHistory([]);
      setCancel([]);
      setCardData({
        ...cardData,
        totalToilLoggedHour: 0,
        totalToilLoggedMins: 0,
      });
    }
  };

  const getUserProfileOverTimeOffRequest = async () => {
    let filterDates = {
      start_date: null,
      end_date: null,
    };

    // if (yearFilter == 0) {
    //   filterDates.start_date = previousYearStartDate;
    //   filterDates.end_date = previousYearEndDate;
    // } else if (yearFilter == 1) {
    //   filterDates.start_date = currentYearStartDate;
    //   filterDates.end_date = currentYearEndDate;
    // } else if (yearFilter == 2) {
    //   filterDates.start_date = futureYearStartDate;
    //   filterDates.end_date = futureYearEndDate;
    // }
    var datesOpt = yearFilter.split(" ");

    filterDates.start_date = moment(
      datesOpt[0],
      AppConstants.defaultDateFormat
    ).format(AppConstants.defaultDateFormatDashed);

    filterDates.end_date = moment(
      datesOpt[2],
      AppConstants.defaultDateFormat
    ).format(AppConstants.defaultDateFormatDashed);

    dispatch(
      getUserProfileOverTimeOff({
        token: token,
        user_id: user.user_id,
        Data: filterDates,
      })
    )
      .then(unwrapResult)
      .then(() => { })
      .catch((err) => {
        Applogger("Error at getUserProfileOverTimeOff", err.message);
      });
  };

  const getUserProfileOverTimeRequest = async () => {
    let filterDates = {
      start_date: null,
      end_date: null,
    };

    var datesOpt = yearFilter.split(" ");

    // console.log(" ===== datesOpt ====== ", datesOpt);
    // console.log(
    //   " ===== datesOpt 1 ====== ",
    //   moment(datesOpt[0], AppConstants.defaultDateFormat).format(
    //     AppConstants.defaultDateFormatDashed
    //   )
    // );
    // console.log(
    //   " ===== datesOpt 2 ====== ",
    //   moment(datesOpt[2], AppConstants.defaultDateFormat).format(
    //     AppConstants.defaultDateFormatDashed
    //   )
    // );

    filterDates.start_date = moment(
      datesOpt[0],
      AppConstants.defaultDateFormat
    ).format(AppConstants.defaultDateFormatDashed);

    filterDates.end_date = moment(
      datesOpt[2],
      AppConstants.defaultDateFormat
    ).format(AppConstants.defaultDateFormatDashed);

    // if (yearFilter == 0) {
    //   filterDates.start_date = previousYearStartDate;
    //   filterDates.end_date = previousYearEndDate;
    // } else if (yearFilter == 1) {
    //   filterDates.start_date = currentYearStartDate;
    //   filterDates.end_date = currentYearEndDate;
    // } else if (yearFilter == 2) {
    //   filterDates.start_date = futureYearStartDate;
    //   filterDates.end_date = futureYearEndDate;
    // }

    dispatch(
      getUserProfileOverTime({
        token: token,
        user_id: user.user_id,
        Data: filterDates,
      })
    )
      .then(unwrapResult)
      .then((response) => { })
      .catch((err) => {
        Applogger("Error at getUserProfileOverTime", err.message);
      });
  };

  const cancelOverTime = (id) => {
    setCurrentOverId(id);
  };

  const cancelOverTimeRequest = (data) => {
    dispatch(cancelUserProfileOverTime({ token, currentOverId, data }))
      .then(unwrapResult)
      .then((res) => {
        // if (res.status == 200) {
        getUserProfileOverTimeRequest();
        setCurrentOverId("");
        // }
      })
      .catch((err) => {
        Applogger("Error at cancelUserProfileOverTime", err.message);
        setCurrentOverId("");
      });
  };

  return (
    <div className="animate__animated animate__fadeInDown">
      <h2 className="profile_head_2 fw-bold">{t("overtime")}</h2>

      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <form className="mt-2 d-flex flex-col">
          <label className="fs-6 fw-bold">{t("leaveYear")}</label>
          {/* <select
            className="filter-form"
            value={yearFilter}
            onChange={(e) => setYearFilter(e.target.value)}
          >
            <option value={0} className="fw-bold" selected>{`01 Jan ${
              currentYear - 1
            } - 31 Dec ${currentYear - 1}`}</option>
            <option
              value={1}
              className="fw-bold"
            >{`01 Jan ${currentYear} - 31 Dec ${currentYear}`}</option>
            <option value={2} className="fw-bold">{`01 Jan ${
              currentYear + 1
            } - 31 Dec ${currentYear + 1}`}</option>
          </select> */}
          <LeaveYearDropDownOpts
            yearFilter={yearFilter}
            onChange={(val) => setYearFilter(val)}
            currentYear={currentYear}
          />
        </form>
      </div>

      <div className="d-flex gap-2 mt-2 flex-wrap-sm">
        <PayableCard
          overtimeOff={
            overTimeDetails.length >= 0 ? overTimeDetails.length : null
          }
          overtimeCount={currentOverTime.length + overTimeHistory.length}
          cardData={cardData}
          heading={t("timeOffInLieuToil")}
          firstHeading="TOIL Logged"
          secondHeading="TOIL Taken"
          thirdHeading="TOIL Balance"
          mode="TOIL"
        />
      </div>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignContent: "center",
          marginTop: 10,
          alignItems: "center",
        }}
      >
        {createOvertimeLog && (
          <a
            href={AppConstants.routes.profile_log_overtime}
            className="btn btn-primary-cs fw-bold"
          >
            {t("logOvertime")}
          </a>
        )}
      </div>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header mt-4 bg-light-cs" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#currentFuture2"
              aria-expanded="true"
              aria-controls="currentFuture2"
            >
              <h6 className="text-black my-2">
                {t("currentAndFuture")} ({currentOverTime.length})
              </h6>
            </button>
          </h2>
          <div
            id="currentFuture2"
            className="accordion-collapse border-0 collapse"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              {currentOverTime.map((val, index) => {
                return (
                  <OverTimeCard
                    key={index}
                    cancelLodge={cancelOverTime}
                    cardData={val}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#history"
              aria-expanded="true"
              aria-controls="history"
            >
              <h6 className="text-black my-2">
                {t("history")} ({overTimeHistory.length})
              </h6>
            </button>
          </h2>
          <div
            id="history"
            className="accordion-collapse border-0 collapse"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              {overTimeHistory.map((val, index) => {
                return (
                  <OverTimeCard
                    key={index}
                    cancelLodge={cancelOverTime}
                    mode=""
                    cardData={val}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#cancelled"
              aria-expanded="true"
              aria-controls="cancelled"
            >
              <h6 className="text-black my-2">
                {t("cancelled")} ({overTimeCancel.length})
              </h6>
            </button>
          </h2>
          <div
            id="cancelled"
            className="accordion-collapse border-0 collapse"
            aria-labelledby="headingOne"
          >
            <div className="accordion-body p-0">
              {overTimeCancel.length > 0 &&
                overTimeCancel.map((val, index) => {
                  return (
                    <OverTimeCard key={index} mode="cancel" cardData={val} />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <OverTimeCancelModal cancelRequest={cancelOverTimeRequest} />
    </div>
  );
}
