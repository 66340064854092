import React, { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { Applogger } from "../../Helpers/Logger";
import { getUserProfileOverTimeOff } from "../../Redux/reducers/ProfileReducers";
import { useTranslation } from "react-i18next";
import { getEvents } from "../../Redux/reducers/StaffingReducer";
import { getUserRolePrevilages } from "../../Redux/reducers/SettingsReducer";
import { get } from "lodash";
import { useTitleHandler } from "../../customHooks/useTitleHandler";
import { DashboardAbsenceSummaryReport } from "../../Redux/reducers/DashboardReducer";
import ReactHtmlParser from "react-html-parser";
import Header from "../../components/Header/Header";
import OverViewCard from "../../components/DashboardCards/OverViewCard/OverViewCard";
import SummaryCard from "../../components/DashboardCards/SummaryCard/SummaryCard";
import Spinner from "../../components/Spinner";
import AppConstants from "../../Helpers/AppConstants";
import UseAbsenceTypes from "../../Helpers/UseAbsenceTypes";
import useLogoutHandler from "../../Helpers/useLogoutHandler";
import ZendeskChatWidget from "../../Helpers/Zendesk";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UseCompanySettings from "../../Helpers/UseCompanySettings";
import DashboardCard from "../../components/DashboardCards/DashboardCard/DashboardCard";
import useLocalisedConstants from "../../customHooks/useLocalisedConstants";
import "react-circular-progressbar/dist/styles.css";
import "./Homepage.css";

function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Custom Hooks
  UseAbsenceTypes(false);
  UseCompanySettings(false);
  useTitleHandler(t("dtDdashboard"));
  useLogoutHandler();

  const { lng } = useSelector((state) => state.languageReducer);
  const { loading } = useSelector((state) => state.DashboardReducer);
  const { loading: tsLoading } = useSelector((state) => state.timesheet);
  const { loading: calLoading } = useSelector((state) => state.calender);
  const { loading: staffLoading } = useSelector((state) => state.staffing);
  const { loading: empLoading } = useSelector((state) => state.employees);
  const { loading: settLoading, userPrevilages: moduleUserPrevilages } =
    useSelector((state) => state.settings);
  const {
    user,
    token,
    jwt,
    loading: authLoading,
    userPrevilages,
  } = useSelector((state) => state.AuthenticationReducer);

  const { exist: homeSummary } = usePrevilagesExist(
    AppConstants.previlages.homeSummary
  );
  const { exist: showCards } = usePrevilagesExist(
    AppConstants.previlages.otherCards
  );

  const appConstants = useLocalisedConstants();

  const currentYear = new Date().getFullYear();

  const companyId = get(user, "company_id", "");
  const currentYearEndDate = `31-12-${currentYear}`;
  const currentYearStartDate = `01-01-${currentYear}`;

  const pending = "pending";

  const loadScript = () => {
    // var head = document.getElementsByTagName("head")[0];
    // var scripts = head.getElementsByTagName("script");
    // if (scripts.length > 0) {
    //   head.removeChild(scripts[0]);
    // }
    // let script = document.createElement("script");
    // script.src = 'https://static.zdassets.com/ekr/snippet.js?key=07c5f467-f5f7-4640-b19a-ed076cf3c8d3';
    // script.id = "ze-snippet";
    // script.type = "text/javascript";
    // // scriptD.text = `zE('messenger', 'loginUser', function(callback) {
    // //   callback('${jwt}');
    // //   Applogger('The widget has been opened!');
    // //   } );
    // document.head.append(script);
    // setTimeout(() => {
    //   setJWT();
    // }, 2000);
  };

  const setJWT = () => {
    // script.onload = () => {
    // Initialize the Messenger widget
    window.zESettings = {
      webWidget: {
        authenticate: {
          jwtFn: () => jwt,
        },
      },
    };

    //   // Wait for the Messenger widget to be fully initialized
    window.zE("webWidget", "addEventListener", "ready", () => {
      // Call the loginUser method with the JWT token
      window.zE("messenger", "loginUser", (callback) => {
        callback(`${jwt}`);
        Applogger("The widget has been opened!");
      });
    });

    const scriptD = document.createElement("script");
    scriptD.type = "text/javascript";
    scriptD.text = `zE('messenger', 'loginUser', function(callback) {
      callback('${jwt}');
      Applogger('The widget has been opened!');
      } );
    `;

    //   scriptD.text = `zE('messenger', 'loginUser', function(callback) {
    //   callback('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6ImFwcF82MzVmNTFhMGU5NDE3ZTAwZmZkZDk4Y2UifQ.eyJleHRlcm5hbF9pZCI6IjEyMzMzIiwibmFtZSI6ImFoc2FuLW5ldzEiLCJlbWFpbCI6ImFkbWlubmV3MUBnbWFpbC5jb20iLCJzY29wZSI6InVzZXIifQ.5GRFUemJ0o9Qyc5ZqyMd9VYo4qHDP1SPEpFAScBRNY0')
    //   Applogger('The widget has been opened!');
    // });`;
    document.body.appendChild(scriptD);
  };

  useEffect(() => {
    if (token) {
      getShiftsList();
      callSummaryData();
      callOverTimeData();

      // Required
      getUserRolePrevilagesHandler();
    }
  }, []);

  useEffect(() => {
    loadScript();
  }, [jwt, token]);

  const getShiftsList = () => {
    dispatch(getEvents({ token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getEvents", res);
      })
      .catch((err) => {
        Applogger("Error at getEvents", err.message);
      });
  };

  const getUserRolePrevilagesHandler = () => {
    dispatch(getUserRolePrevilages({ token, companyId }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserRolePrevilages", res);
      })
      .catch((err) => {
        Applogger("Error at getUserRolePrevilages", err);
      });
  };

  function callOverTimeData() {
    const overTimeData = {
      start_date: currentYearStartDate,
      end_date: currentYearEndDate,
    };
    dispatch(
      getUserProfileOverTimeOff({
        token: token,
        user_id: user.user_id,
        Data: overTimeData,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("response at getUserProfileOverTimeOff", response);
      })
      .catch((err) => {
        Applogger("Error at getUserProfileOverTimeOff", err.message);
      });
  }

  function callSummaryData() {
    dispatch(DashboardAbsenceSummaryReport({ token, lng }))
      .then(unwrapResult)
      .then((res) => { })
      .catch((err) => {
        Applogger("Error at DashboardAbsenceSummaryReport", err);
      });
  }

  const isDataLoading = () => {
    return (
      loading == pending ||
      settLoading == pending ||
      staffLoading == pending ||
      tsLoading == pending ||
      calLoading == pending ||
      authLoading == pending ||
      empLoading == pending
    );
  };

  const isModuleAllowed = (module) => {
    return moduleUserPrevilages.some((moduleUserPrevilage) => {
      return (
        moduleUserPrevilage.key === module &&
        moduleUserPrevilage.children.some((child) => {
          return userPrevilages.includes(child.key);
        })
      );
    });
  };

  return (
    <div>
      <Header active={"dashboard"} />
      {isDataLoading() && <Spinner />}
      <ZendeskChatWidget
        // accountKey="FWjW9gnROjesa2X7h3JDOop5lbkjtS0St0IsRqkP"
        accountKey={"FWjW9gnROjesa2X7h3JDOop5lbkjtS0St0IsRqkP"}
        jwt={jwt}
        locale="en-US"
        theme="light"
        department="sales"
      // onChatClosed={() => console.log("Chat closed")}
      // onChatStarted={() => console.log("Chat started")}
      />
      <br />
      <div className="sp-parent-container">
        <div className="sp_main">
          <div className="d-flex gap-2 flex-wrap-sm">
            <OverViewCard />
            {homeSummary && <SummaryCard />}
          </div>
        </div>
        <div className="sp_main pt-4">
          {showCards && (
            <div className="cardGrid">
              {appConstants.dashboardCardsList.map((data, index) => {
                const {
                  title,
                  primary_link_value,
                  short_description,
                  key,
                  short_description_image,
                  module,
                } = data;
                if (isModuleAllowed(module)) {
                  return (
                    <DashboardCard
                      key={index}
                      title={t(title)}
                      link={primary_link_value}
                      shortDescription={ReactHtmlParser(short_description)}
                      shortDescriptionImage={short_description_image}
                      cardType={key}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default App;
