import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { getAlphaNumericString } from "../../Helpers/AppValidators";
import { getCompanyEmployeesRequest } from "../../Redux/reducers/SettingsReducer";
import { EmployeeBackButton } from "./Forms/Components/Butttons";
import { employeesRequest } from "../../Redux/reducers/EmployeesReducer";
import Teams from "./Teams";
import AddEmployee from "./AddEmployee";
import Filters from "./Forms/Filter/Filters";
import AppConstants from "../../Helpers/AppConstants";
import AddMultipleEmployees from "./AddMultipleEmployees";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import EditEmployees from "../OnBoardingComponents/EditEmployees";

export default function EmployeeSection({ searchQuery, status, add }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducers
  const { lng } = useSelector((state) => state.languageReducer);
  const { allEmployees } = useSelector((state) => state.employees);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  // console.log("Alllmploye++++++++++++", allEmployees);
  // Params
  const [params] = useSearchParams();
  const offset = params.get("offset");
  const newQueryParameters = new URLSearchParams();

  // Priviliges
  const { exist: createEmployee } = usePrevilagesExist(
    AppConstants.previlages.createEmployee
  );

  // Constants
  const filtersObj = {
    searchQuery: "",
    name: "A-Z",
    workingStatus: "any",
    role: "any",
    location: "any",
  };

  // Local States
  const [offSet, setOffSet] = useSearchParams();
  const [employeesFilters, setEmployeesFilters] = useState(filtersObj);
  const [searchQueryLocal, setSearchQuery] = useState("");
  const [showAddEmployee, setShowAddEmployee] = useState("");
  const [uploadedEmployees, setUploadedEmployees] = useState(false);
  const [addMultipleEmployees, setAddMultipleEmployees] = useState(false);
  const [employeeAddMode, setAllEmployeeMode] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (searchQuery) {
      setSearchQuery(searchQuery);
    }
    if (status) {
      setEmployeesFilters({ ...employeesFilters, workingStatus: status });
    }
    if (add == "add_people") {
      setShowAddEmployee(true);
      setAddMultipleEmployees(false);
    } else if (add == "add_people_in_bulk") {
      setShowAddEmployee(false);
      setAddMultipleEmployees(true);
    }
  }, [searchQuery, status, add]);

  useEffect(() => {
    if (!token) {
      navigate(AppConstants.routes.login);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getEmployees(employeesFilters);
    }
  }, [offset]);

  useEffect(() => {
    if (token) {
      newQueryParameters.set("offset", 0);
      setOffSet(newQueryParameters);
      getEmployees(employeesFilters, true);
    }
  }, [employeesFilters]);

  const getOffSet = () => {
    return offset ? offset : 0;
  };

  function getEmployees(employeesFilters, resetOffset = false) {
    dispatch(
      employeesRequest({
        token,
        lng,
        offset: resetOffset ? 0 : getOffSet(),
        employeesFilters: {
          ...employeesFilters,
          searchQuery: searchQueryLocal,
        },
        //type list hide for Current user profile show purpose
        // type: "list",
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at employeesRequest", response);
      })
      .catch((err) => {
        Applogger("Error at employeesRequest", err.message);
      });
  }

  const getCompanyAllEmployeesRequest = () => {
    dispatch(getCompanyEmployeesRequest({ token }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getCompanyEmployeesRequest", res);
      })
      .catch((err) => {
        Applogger("Error at getCompanyEmployeesRequest", err);
      });
  };

  // const sortingTypes = {
  //   FaToZ: "FA-Z",
  //   FzToA: "FZ-A",
  //   LAToz: "LA-Z",
  //   LzToA: "LZ-A",
  //   any: "any",
  // };

  // const employeesFilteredHandler = () => {
  //   let finalArray = [...allEmployees];

  //   if (searchQueryLocal) {
  //     finalArray = finalArray.filter((item) => {
  //       return (
  //         item.first_name
  //           .toLowerCase()
  //           .includes(searchQueryLocal.toLowerCase()) ||
  //         item.last_name.toLowerCase().includes(searchQueryLocal.toLowerCase())
  //       );
  //     });
  //   }

  //   finalArray = finalArray.sort((a, b) => {
  //     const val = employeesFilters.name;
  //     if (val === sortingTypes.FaToZ) {
  //       return a.first_name.localeCompare(b.first_name);
  //     } else if (val === sortingTypes.FzToA) {
  //       return b.first_name.localeCompare(a.first_name);
  //     } else if (val === sortingTypes.LAToz) {
  //       return a.last_name.localeCompare(b.last_name);
  //     } else if (val === sortingTypes.LzToA) {
  //       return b.last_name.localeCompare(a.last_name);
  //     }
  //   });

  //   if (employeesFilters.workingStatus !== "any") {
  //     finalArray = finalArray.filter((item) => {
  //       return (
  //         getAlphaNumericString(item.working_status) ==
  //         getAlphaNumericString(employeesFilters.workingStatus)
  //       );
  //     });
  //   }

  //   if (employeesFilters.role !== "any") {
  //     if (employeesFilters.role) {
  //       finalArray = finalArray.filter((item) => {
  //         return item.role_id == employeesFilters.role;
  //       });
  //     }
  //   }

  //   if (employeesFilters.location !== "any") {
  //     if (employeesFilters.location) {
  //       finalArray = finalArray.filter((item) => {
  //         return item.work_place_id == employeesFilters.location;
  //       });
  //     }
  //   }
  //   return finalArray;
  // };

  const cancelClickHandler = () => {
    setShowAddEmployee(false);
    setAddMultipleEmployees(false);
  };

  const employeeCreatedHandler = () => {
    getCompanyAllEmployeesRequest();
    getEmployees(filtersObj);
    setAllEmployeeMode(!employeeAddMode);
    if (addMultipleEmployees) {
      setUploadedEmployees(true);
    }
  };

  const isFiltering = () => {
    return JSON.stringify(employeesFilters) != JSON.stringify(filtersObj);
  };

  return (
    <div>
      <div>
        <div className="d-flex animate__animated animate__zoomInDown justify-content-between flex-wrap align-items-center">
          {!showAddEmployee &&
            !addMultipleEmployees &&
            !uploadedEmployees &&
            createEmployee && (
              <div className="dropend">
                <button
                  className="btn btn-primary-cs me-2 mb-2"
                  type="button"
                  onClick={() => setShowAddEmployee(!showAddEmployee)}
                  aria-expanded="false"
                >
                  {t("addANewEmployee")}
                </button>
                <button
                  className="btn btn-primary-cs me-2 mb-2"
                  type="button"
                  onClick={() => setAddMultipleEmployees(!addMultipleEmployees)}
                  aria-expanded="false"
                >
                  {t("addMultipleEmployees")}
                </button>
              </div>
            )}
        </div>
        {showAddEmployee && !uploadedEmployees && (
          <AddEmployee
            employeeCreated={employeeCreatedHandler}
            cancelClick={cancelClickHandler}
            setShowAddEmployee={setShowAddEmployee}
          />
        )}
        {uploadedEmployees && (
          <div>
            <EditEmployees isOnboarding={false} />
          </div>
        )}
        <div
          className={`col-12 col-sm-12 col-md d-flex justify-content-between`}
        >
          {addMultipleEmployees && (
            <EmployeeBackButton
              onClick={() => {
                cancelClickHandler();
                setUploadedEmployees(false);
              }}
              title={t("cancelBulkUpload")}
              addClass=""
            />
          )}
          {/* {uploadedEmployees && (
            <EmployeeFormButton
              onClick={() => {
                cancelClickHandler();
                setUploadedEmployees(false);
              }}
              title={t("Finish")}
              // addClass={`${addMultipleEmployees && "ml-4"}`}
            />
          )} */}
        </div>
        {addMultipleEmployees && (
          <AddMultipleEmployees employeeCreated={employeeCreatedHandler} />
        )}
        <div className="filter-form-group d-flex justify-content-end">
          <button
            className="btn btn-primary-cs me-2 mb-2"
            type="button"
            onClick={() => {
              newQueryParameters.set("offset", 0);
              setOffSet(newQueryParameters);
              setEmployeesFilters(filtersObj);
              getEmployees(filtersObj, true);
              setSearchQuery("");
            }}
            aria-expanded="false"
          >
            {t("clearFilters")}
          </button>
          <button
            className="btn btn-primary-cs mb-2"
            type="button"
            onClick={() => getEmployees(employeesFilters)}
            aria-expanded="false"
          >
            {t("search")}
          </button>
        </div>
        <Filters
          employeesFilters={employeesFilters}
          searchQuery={searchQueryLocal}
          setSearchQuery={setSearchQuery}
          setEmployeesFilters={setEmployeesFilters}
        />
      </div>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <Teams employeeData={allEmployees} isFiltering={isFiltering()} />
      </div>
    </div>
  );
}
