import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Applogger } from "../../Helpers/Logger";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { get, isNaN } from "lodash";
import { showFaliureToast, showWarningToast } from "../../Utilities";
import {
  getUserTimeSheetHistory,
  getUserTimeSheetDetails,
  toggleBreak,
  toggleClock,
} from "../../Redux/reducers/TimesheetReducer";
import { getUserWorkTimePattern } from "../../Redux/reducers/AuthenticationReducer";
import { getPlacesOfWork } from "../../Redux/reducers/SettingsReducer";
import {
  changeUserProfileStatus,
  getUserProfile,
} from "../../Redux/reducers/ProfileReducers";
import AppConstants from "../../Helpers/AppConstants";
import moment from "moment";
import Map from "../../components/Map";
import Spinner from "../../components/Spinner";
import TimeSheetCard from "./TimeSheetCard";
import ClockPopup from "./ClockPopup";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import UserStatusModal from "../../components/Profile/profileDetails/UserStatusModal";
import format from "date-fns/format";

export default function TimePanel({
  refreshStatus,
  reloadPage,
  mapRequired = true,
  addClass = "",
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user, token } = useSelector((state) => state.AuthenticationReducer);
  const { lng } = useSelector((state) => state.languageReducer);
  const { placesOfWork } = useSelector((state) => state.settings);
  const {
    lastClockIn,
    clockStatus,
    breakStatus,
    userTimeSheetDetails,
    loading,
  } = useSelector((state) => state.timesheet);
  const { user: currentUser } = useSelector((state) => state.profile);
  const { exist: clockInOutBreak } = usePrevilagesExist(
    AppConstants.previlages.clockInOutBreak
  );

  const companyId = get(user, "company_id", "");
  const current = new Date();

  const date_cs = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [userStatusModal, setUserStatusModal] = useState(false);
  const [isPermission, setIsPermission] = useState(false);
  const [timeSheetDetails, setTimeSheetDetails] = useState([]);
  const [clockModalStatus, setClockModalStatus] = useState(false);

  useEffect(() => {
    if (token) {
      if (placesOfWork.length == 0) {
        callGetWorkPlacesAPI();
      }
      if (!currentUser) {
        getProfileData();
      }
      getTimeSheetResponse();
      getUserTimeSheetDetailsHandler();
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setIsPermission(true);
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function (error) {
          Applogger("Error at permission", error);
          setIsPermission(false);
        },
        { enableHighAccuracy: true }
      );
    }
  }, []);

  const getProfileData = () => {
    dispatch(getUserProfile({ bearer_token: token, user_id: user.id, lng }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getUserProfile", response);
      })
      .catch((err) => {
        Applogger("Error at getUserProfile", err);
      });
  };

  useEffect(() => {
    if (refreshStatus && token) {
      getTimeSheetResponse();
      getUserTimeSheetDetailsHandler();
      reloadPage(false);
    }
  }, [refreshStatus]);

  useEffect(() => {
    if (userTimeSheetDetails) {
      setTimeSheetDetails(userTimeSheetDetails);
    }
  }, [userTimeSheetDetails, breakStatus]);

  const callGetWorkPlacesAPI = () => {
    dispatch(getPlacesOfWork({ token, lng, companyId }))
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getPlacesOfWork", response);
      })
      .catch((err) => {
        Applogger("Error at getPlacesOfWork", err.message);
      });
  };

  const toggleBreakHandler = () => {
    dispatch(toggleBreak({ token: token, userId: user.user_id }))
      .then(unwrapResult)
      .then((res) => {
        getUserTimeSheetDetailsHandler();
        reloadPage(true);
        Applogger("Response at toggleBreak", res);
      })
      .catch((err) => {
        Applogger("Error at toggleBreak", err.message);
      });
  };

  const getTimeSheetResponse = () => {
    dispatch(
      getUserTimeSheetHistory({
        token,
        dateRange: { startDate: "", endDate: "" },
        lng,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        Applogger("Response at getUserTimeSheetHistory", response);
      })
      .catch((err) => {
        Applogger("Error at getUserTimeSheetHistory", err);
      });
  };

  const setUserStatus = (userStatus) => {
    var userId = user.user_id;
    let data = {
      token: token,
      currentStatus: userStatus,
      userId: userId,
    };

    dispatch(changeUserProfileStatus(data))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at changeUserProfileStatus at Clock out", res);
      })
      .catch((err) => {
        Applogger("Error at changeUserProfileStatus at Clock out", err.message);
      });
  };

  const handleClockInOutUser = (statusValue) => {
    // In Out
    if (!isPermission) {
      showWarningToast(t("locationPermission"));
      return;
    }
    if (statusValue == AppConstants.clockStates.in) {
      getUserCurrentLocationCoordinates();
    } else {
      handleAPICalls(
        AppConstants.clockStates.out,
        AppConstants.workingStatus.not_working
      );
    }
  };

  const handleAPICalls = (clockStatus, workingStatus) => {
    toggleClockHandler(clockStatus);
    // Setting Status as not Working
    setUserStatus(workingStatus);
  };

  const getUserCurrentLocationCoordinates = () => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
      });
    } else {
      setIsLoading(false);
      Applogger("Geolocation not supported");
      showWarningToast(t("locationPermission"));
      setUserStatusModal(true);
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      calculateLatLongDifference(latitude, longitude);
    }

    function error(err) {
      setIsLoading(false);
      Applogger("Unable to retrieve user location", err);
      showWarningToast(t("locationPermission"));
      setUserStatusModal(true);
    }
  };

  const calculateLatLongDifference = (currentLat, currentLong) => {
    var haversine = require("haversine-distance");

    let userWorkPlace = null;

    placesOfWork.forEach((place) => {
      if (get(place, "work_place_id", "") == get(user, "work_place_id", null)) {
        userWorkPlace = place;
      }
    });

    //First point in your haversine calculation
    var point1 = { lat: currentLat, lng: currentLong };

    //Second point in your haversine calculation
    var point2 = {
      lat: get(userWorkPlace, "latitude", ""),
      lng: get(userWorkPlace, "longitude", ""),
    };

    var haversine_m = haversine(point1, point2); // Results in meters (default)
    // var haversine_km = haversine_m / 1000; // Results in kilometers

    if (!isNaN(haversine_m)) {
      if (
        parseInt(haversine_m) <
        get(userWorkPlace, "radius", AppConstants.metersRange)
      ) {
        // Set Status as Working from usual location
        handleAPICalls(
          AppConstants.clockStates.in,
          AppConstants.workingStatus.working_from_usual_location
        );
      } else {
        // Show Popup for managing status
        setUserStatusModal(true);
      }
    } else {
      // Show Popup for managing status
      setUserStatusModal(true);
    }
    setIsLoading(false);
  };

  const toggleClockHandler = (clockStatus) => {
    dispatch(
      toggleClock({
        token: token,
        status: clockStatus,
        // clockStatus == "clocked_in"
        //   ? AppConstants.clockStates.out
        //   : AppConstants.clockStates.in,
        userId: user.user_id,
        latitude: location.lat,
        longitude: location.lng,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        handleGetUserWorkTimePattern();
        getUserTimeSheetDetailsHandler();
        setClockModalStatus(false);
        reloadPage(true);
        Applogger("Response at toggleClock", res);
      })
      .catch((err) => {
        showFaliureToast(err.data);
        Applogger("Error at toggleClock", err);
        if (get(err, "data", "") == "\nUser Already Clocked Out") {
          handleGetUserWorkTimePattern();
          getUserTimeSheetDetailsHandler();
          setClockModalStatus(false);
          reloadPage(true);
          // window.location.reload();
        }
        Applogger("error at the api call ", get(err, "data", ""));
      });
  };

  const handleGetUserWorkTimePattern = () => {
    dispatch(
      getUserWorkTimePattern({ token, role_id: get(user, "role_id", "") })
    )
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserWorkTimePattern", res);
      })
      .then((err) => {
        Applogger("Error at getUserWorkTimePattern", err);
      });
  };

  const getUserTimeSheetDetailsHandler = () => {
    dispatch(getUserTimeSheetDetails({ token: token, lng }))
      .then(unwrapResult)
      .then((res) => {
        Applogger("Response at getUserTimeSheetDetails", res);
      })
      .catch((err) => {
        Applogger("Error at getUserTimeSheetDetails", err.message);
      });
  };

  const breakStatusHandler = () => {
    if (breakStatus == "OFF") {
      return clockInOutBreak ?? false;
    } else {
      return clockInOutBreak ?? false;
    }
  };

  const clockStatusHandler = () => {
    if (clockStatus == "clocked_in") {
      return clockInOutBreak ?? false;
    } else {
      return clockInOutBreak ?? false;
    }
  };

  return (
    <div
      data-aos-duration="1500"
      data-aos="fade-right"
      className={`col-sm-12 col-md-12 col-lg-8 ${addClass}`}
    >
      <span>{(loading == "pending" || isLoading) && <Spinner />}</span>
      <UserStatusModal
        modalStatus={userStatusModal}
        onCancel={() => setUserStatusModal(false)}
        setStatus={(status) => {
          setUserStatusModal(false);
          handleAPICalls(AppConstants.clockStates.in, status);
        }}
        changeStatus={(e) => Applogger("Not Need Function")}
        status={t(get(user, "user.working_status", ""))}
      />
      <ClockPopup
        clockInOutBreak={clockInOutBreak}
        toggleClock={() => {
          handleClockInOutUser(
            clockStatus == "clocked_in"
              ? AppConstants.clockStates.out
              : AppConstants.clockStates.in
          );
        }}
        clockStatus={clockStatus}
        modalStatus={clockModalStatus}
        onCancel={() => setClockModalStatus(false)}
        user={get(user, "first_name", "") + " " + get(user, "last_name", "")}
        status={clockStatus == "clocked_in" ? "Out" : "In"}
      />
      <div className="main-panel bg-white border-custom overflow-hidden">
        <div className="panel-head">
          <div className="animate__animated animate__bounceIn">
            {user && (
              <h3 className="mb-0 p-0">{`${user.first_name} ${user.last_name}`}</h3>
            )}
            <span className="small text-black-50">
              {` ${clockStatus == "clocked_in" ? t("clockedIn") : t("clockedOut")
                }`}
            </span>
            {/* {clockStatus == "clocked_in" && lastClockIn && (
              <span className="small text-black-50 mx-1">{`(${moment
                .unix(lastClockIn)
                .format(AppConstants.timeFormat)})`}</span>
            )} */}
            {clockStatus == "clocked_in" && lastClockIn && (
              <span className="small text-black-50 mx-1">
                {`(${moment.unix(lastClockIn).fromNow()})`}
              </span>
            )}
          </div>
          <div className="animate__animated animate__bounceIn">
            {breakStatusHandler() && (
              <button
                onClick={toggleBreakHandler}
                className={`btn btn-sm-pink btn-primary-cs me-2 ${clockStatus !== "clocked_in" ? "d-none" : "d-auto"
                  }`}
              >
                <span>
                  {breakStatus == "OFF" ? t("startBreak") : t("endBreak")}
                </span>
              </button>
            )}
            {clockStatusHandler() && (
              <button
                onClick={() => setClockModalStatus(true)}
                className="btn btn-primary-outline-cs"
              >
                <span>
                  {clockStatus == "clocked_in" ? t("clockOut") : t("clockIn")}
                </span>
              </button>
            )}
          </div>
        </div>
        <hr />
        {mapRequired && (
          <div className="panel-body p-2">
            <br />
            <div className="animate__animated animate__bounceIn d-flex justify-content-between align-items-end flex-wrap">
              <h4 className="h4 fw-bold lexen-font">{t("timeLog")}</h4>
              <h6 className="h6 fw-bold">
                {t("date")}: {format(current, "dd/MM/yyyy")}
              </h6>
            </div>
            <div id="MapAndStatusDiv">
              <Card style={{ overflow: "auto", maxHeight: 250 }}>
                {timeSheetDetails.map((timeSheet, index) => {
                  const label = get(timeSheet, "label", "");
                  const time = get(timeSheet, "time", "");
                  const date = get(timeSheet, "date", "");
                  return (
                    <TimeSheetCard
                      key={index}
                      label={label}
                      time={time}
                      date={date}
                    />
                  );
                })}
              </Card>
              <Map
                isPermission={isPermission && location.lat && location.lng}
                coords={location}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
