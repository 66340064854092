import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { unixToDateFormater } from "../../Utilities";
import { ComponentToPrint } from "./PrintStaffing";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import ReactToPrint from "react-to-print";
import Tooltip from "../Tooltip";

export default function StaffingStatusCard(props) {
  const {
    enableView,
    enablePublish,
    enableUnPublish,
    enablePrint,
    enableCopy,
    enableDelete,
    enableEdit,
    enableChange,
  } = props;

  const componentRef = useRef();

  const allUsers = get(props, "users", []);
  const { t } = useTranslation();

  return (
    <div className="row sec-cs-card mb-4 flex-wrap">
      <div style={{ display: "none" }}>
        {" "}
        <ComponentToPrint printData={props} ref={componentRef} />
      </div>
      <div className="col-md-2 d-flex align-items-center b-r-lgt py-4">
        <p className="mb-0 text-center">
          {unixToDateFormater(props.Day, `ddd DD MMM`)}
        </p>
      </div>
      <div className="col-md-10 py-4">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="col-md-3">
            <a
              href="#"
              className="text-primary capitalize"
              data-mdb-toggle="collapse"
            >
              {props.shift ? props.shift : "night"}
            </a>
          </div>
          <div className="col-md-9 d-flex justify-content-end">
            <ul className="d-flex list-unstyled mb-0">
              <li className="text-primary  fs-5 dropdown-nav nav-item dropdown">
                <button
                  type="button"
                  id="dropdownMenuButton"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ri-more-2-line " />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {enableView && (
                    <li onClick={props.onViewClick} className="dropdown-item">
                      <i className="ri-eye-line" />
                      <span className="ms-2">{t("View")}</span>
                    </li>
                  )}

                  {enableEdit && (
                    <li onClick={props.onEditClick} className="dropdown-item">
                      <i className="ri-edit-line" />
                      <span className="ms-2">{t("edit")}</span>
                    </li>
                  )}

                  {enablePrint && (
                    <ReactToPrint
                      trigger={() => (
                        <li
                          onClick={props.onPrintClick}
                          className="dropdown-item"
                        >
                          <i className="ri-printer-line" />
                          <span className="ms-2">{t("print")}</span>
                        </li>
                      )}
                      content={() => componentRef.current}
                    />
                  )}
                  {enableChange && (
                    <li
                      onClick={props.onModalClick}
                      className="dropdown-item d-flex align-items-center"
                    >
                      <i className={props.modalButtonIcon} />
                      <span className="ms-2">{props.modalButtonTitle}</span>
                    </li>
                  )}
                  {enableEdit && (
                    <li onClick={props.onRenameClick} className="dropdown-item">
                      <i className="ri-edit-line" />
                      <span className="ms-2">{t("rename")}</span>
                    </li>
                  )}

                  {enableCopy && (
                    <li onClick={props.copyClick} className="dropdown-item">
                      <i className="ri-file-copy-line" />
                      <span className="ms-2">{t("Copy")}</span>
                    </li>
                  )}
                  {enableDelete && (
                    <li onClick={props.onDeleteClick} className="dropdown-item">
                      <i className="ri-delete-bin-line" />
                      <span className="ms-2">{t("delete")}</span>
                    </li>
                  )}
                </ul>
                {/* )} */}
              </li>
            </ul>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-start">
          <span className="me-3 capitalize">{`Total: ${props.duration ? props.duration : ""
            } ${props.duration > 1 ? "days" : "day"} `}</span>
          {allUsers.length > 0 && (
            <React.Fragment>
              <p>{t("assignedUsers")}</p>{" "}
              <Avatar.Group style={{ marginTop: -10, marginLeft: 10 }}>
                {allUsers.slice(0, 10).map((val, index) => {
                  return val.profile_image == null ? (
                    <Tooltip title={`${val.first_name} ${val.last_name}`}>
                      <Avatar
                        size={35}
                        className="capitalize"
                        style={{
                          background: "#00A5E9",
                          textTransform: "capitalize",
                          fontSize: 13,
                        }}
                        key={index}
                      >{`${val.first_name ? val.first_name.slice(0, 1) : ""}${val.last_name ? val?.last_name.slice(0, 1) : ""
                        }`}</Avatar>
                    </Tooltip>
                  ) : (
                    <Tooltip title={`${val.first_name} ${val.last_name}`}>
                      <Avatar size={35} key={index} src={val.profile_image} />
                    </Tooltip>
                  );
                })}
                {allUsers.length > 10 && (
                  <Avatar
                    size={35}
                    style={{
                      background: "#00A5E9",
                      textTransform: "capitalize",
                    }}
                  >
                    +{allUsers.length - 10}
                  </Avatar>
                )}
              </Avatar.Group>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
