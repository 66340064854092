import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AppConstants from "../../Helpers/AppConstants";
export default function TimeSheetCard({ date, time, label }) {
  const { t, i18n } = useTranslation();
  return date && time ? (
    <div className="d-flex mt-2">
      <div className="timeSheetIconView">
        <i
          className={
            label == "Break Started"
              ? "ri-restaurant-fill"
              : label == "Break Ended"
                ? "ri-computer-line"
                : label == "clock out"
                  ? "ri-rest-time-line"
                  : `ri-sun-line`
          }
          style={{ fontSize: 20, color: "#1F62AB" }}
        />
      </div>
      <div className="ml-4">
        <h5 className="capitalize mb-2">{label}</h5>
        {date && time ? (
          <p
            style={{ color: "#1F62AB", fontWeight: "600", letterSpacing: 1.2 }}
          >
            {" "}
            {`${moment.unix(time).format(AppConstants.timeFormat)}, ${moment
              .unix(date)
              .format(AppConstants.dateFormat)}`}
          </p>
        ) : null}
      </div>
    </div>
  ) : null;
}
