import React, { useEffect } from "react";
import { useState } from "react";
import { Collapse } from "antd";
import { Applogger } from "../../Helpers/Logger";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showSuccessToast, unixToDateFormaterOld } from "../../Utilities";
import { showFaliureToast } from "../../Utilities/index";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import {
  deleteMandatoryLeave,
  getMandatoryLeaves,
} from "../../Redux/reducers/CalenderReducer";
import usePrevilagesExist from "../../Helpers/PrivilegesFuncs";
import AppConstants from "../../Helpers/AppConstants";
import MantoryModal from "./MandatoryModal";

export default function MandatoryLeave() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { exist: manageMandatoryLanguage } = usePrevilagesExist(
    AppConstants.previlages.manageMandatoryLanguage
  );

  const { exist: ApproveDeclinePendingLeave } = usePrevilagesExist(
    AppConstants.previlages.ApproveDeclinePendingLeave
  );
  const { lng } = useSelector((state) => state.languageReducer);
  const { allEmployees } = useSelector((state) => state.employees);
  const { token } = useSelector((state) => state.AuthenticationReducer);
  const { mandatoryLeaves } = useSelector((state) => state.calender);

  const [mandatoryLocal, setMandatoryLocal] = useState([]);
  const [showMandatoryModal, setShowMandatoryModal] = useState(false);

  const { Panel } = Collapse;

  useEffect(() => {
    if (token) {
      if (!mandatoryLeaves.length > 0) {
        getMandatoryLeaveHandler();
      }
    }
  }, []);

  useEffect(() => {
    if (mandatoryLeaves) {
      setMandatoryLocal(mandatoryLeaves);
    }
  }, [mandatoryLeaves]);
  const getMandatoryLeaveHandler = () => {
    try {
      dispatch(getMandatoryLeaves({ token, lng }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at getMandatoryLeaves", res);
        })
        .catch((err) => {
          Applogger("Error at getMandatoryLeaves", err);
        });
    } catch (err) {
      Applogger("Error at getMandatoryLeaveHandler", err);
    }
  };

  const deleteMandatoryLeaveHandler = (id) => {
    try {
      dispatch(deleteMandatoryLeave({ token, id, lng }))
        .then(unwrapResult)
        .then((res) => {
          Applogger("Response at deleteMandatoryLeave", res);
          getMandatoryLeaveHandler();
          showSuccessToast("Deleted Mandatory Leave Successfully");
        })
        .catch((err) => {
          Applogger("Error at deleteMandatoryLeave", err);
          showFaliureToast("Mandatory Leave Deletion Failed");
        });
    } catch (err) {
      Applogger("Error at deleteMandatoryLeaveHandler", err);
    }
  };

  const Header = ({ name, startDate, endDate, deductedState, id }) => {
    try {
      return (
        <div className="w-100 align-content-space-around align-items-center row">
          <div className="col-12 col-md-3 col-sm-12">
            <p style={{ textTransform: "capitalize", marginTop: 5 }}>{name}</p>
          </div>
          <div className="col-12 col-md-3 col-sm-12">{`${unixToDateFormaterOld(
            startDate,
            AppConstants.defaultDateFormat
          )} - ${unixToDateFormaterOld(
            endDate,
            AppConstants.defaultDateFormat
          )}`}</div>
          <div className="col-md-3 col-sm-12">
            {deductedState == 1 ? "Deducted " : "Not Deducted"}
          </div>
          {ApproveDeclinePendingLeave && (
            <div className="col-12 col-md-3 col-sm-12">
              <button onClick={() => deleteMandatoryLeaveHandler(id)}>
                <lord-icon
                  src="https://cdn.lordicon.com/kfzfxczd.json"
                  colors={`primary:#1A5B9D`}
                  trigger="hover"
                  style={{ width: 25, height: 25 }}
                ></lord-icon>
              </button>
            </div>
          )}
        </div>
      );
    } catch (err) {
      Applogger("Error at Header", err);
    }
  };
  return (
    <div>
      <MantoryModal
        created={getMandatoryLeaveHandler}
        employees={allEmployees}
        modalStatus={showMandatoryModal}
        onCancel={() => setShowMandatoryModal(false)}
      />
      <div className="d-flex justify-content-between mb-2 flex-wrap">
        <h4 className="fw-bold text-capitalize subheading_2">{`${t(
          "mandatoryLeave"
        )} (${mandatoryLocal.length})`}</h4>
        {ApproveDeclinePendingLeave && (
          <button
            onClick={() => setShowMandatoryModal(true)}
            className="btn btn-sm-dark"
          >
            {t("addMandatoryLeave")}
          </button>
        )}
      </div>
      <p>{t("employeeHolidaysDates")}</p>

      {mandatoryLocal.length > 0 ? (
        <Collapse defaultActiveKey={["1"]}>
          {mandatoryLocal.map((val, index) => {
            const notes = get(val, "notes", "");
            const mandatoryLeaveId = get(val, "mandatory_leave_id", "");
            const endDate = get(val, "end_date", "");
            const name = get(val, "name", "");
            const startDate = get(val, "start_date", "");
            const isDeducted = get(val, "is_deducted", "");
            const users = get(val, "users", []);

            return (
              <Panel
                className="card"
                header={
                  <Header
                    id={mandatoryLeaveId}
                    name={name}
                    startDate={startDate}
                    endDate={endDate}
                    deductedState={isDeducted}
                  />
                }
                key={index}
              >
                <div className="d-flex justfi">
                  <div>
                    <i
                      style={{ fontSize: 15 }}
                      className={`${
                        isDeducted == "1" ? "ri-check-line" : "ri-close-line"
                      }  `}
                    />
                  </div>{" "}
                  <p className="ml-2">{t("employeeannualLeaveDeductions")}</p>
                </div>
                {
                  <p>
                    {t("notes")}: {notes ?? "N/A"}
                  </p>
                }
                <p
                  className="font-bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {"Users: "}
                </p>
                {users.map((listUser, index) => {
                  const { first_name, last_name } = listUser;
                  return (
                    <span key={index}>{`${first_name} ${last_name}, `}</span>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        <h4>{t("noLeaveFound")}</h4>
      )}
    </div>
  );
}
